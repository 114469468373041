import React, { useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"

// Import components
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBanner from "../components/title-banner"
import DateSelectorDesktop from "../components/date-selector-desktop"

const AnnouncementsPage = () => {
  const data = useStaticQuery(FINANCIAL_RESULTS)
  const financialResults = data?.allStrapiFinancialResults?.nodes || []
  const imageData = data?.allImageSharp?.nodes || []

  console.log("financialResults:", financialResults)

  const node = useRef()

  return (
    <Layout>
      <Seo title="Home" />
      <TitleBanner imageData={imageData} scrollRef={node}>
        Financial Results
      </TitleBanner>
      <DateSelectorDesktop
        tabs={{
          Year: [2021, 2020, 2019, 2018, 2017, 2016],
          Full_or_Half_Year: ["Half", "Full"],
        }}
        types={[
          "Presentations",
          "Financial_statements",
          "Result_booklets",
          "Web_casts",
        ]}
        content={financialResults}
      ></DateSelectorDesktop>
    </Layout>
  )
}

export default AnnouncementsPage

const FINANCIAL_RESULTS = graphql`
  {
    allImageSharp(
      filter: {
        fixed: {
          originalName: {
            in: [
              "banner-image-financial-results.png"
              "banner-image-financial-results-mobile.png"
            ]
          }
        }
      }
      sort: { fields: fixed___originalName, order: DESC }
    ) {
      nodes {
        gatsbyImageData
      }
    }
    allStrapiFinancialResults {
      nodes {
        Full_or_Half_Year
        Type
        Year
        PDF_File {
          Date
          Title
          File {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
